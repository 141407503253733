<template>
  <svg :width="width || 34" :height="height || 34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="17" cy="17" r="16" :stroke="fill || '#ED462F'" stroke-width="2"/>
    <path d="M14.9514 7.3999H19.0954L18.3954 20.5039H15.6234L14.9514 7.3999ZM17.0234 27.1959C16.37 27.1959 15.8287 26.9906 15.3994 26.5799C14.97 26.1506 14.7554 25.6279 14.7554 25.0119C14.7554 24.3959 14.97 23.8826 15.3994 23.4719C15.8287 23.0612 16.37 22.8559 17.0234 22.8559C17.658 22.8559 18.1807 23.0612 18.5914 23.4719C19.0207 23.8826 19.2354 24.3959 19.2354 25.0119C19.2354 25.6279 19.0207 26.1506 18.5914 26.5799C18.1807 26.9906 17.658 27.1959 17.0234 27.1959Z" :fill="fill || '#ED462F'"/>
  </svg>
</template>

<script>
import icon_config from "@/mixins/icon_config";

export default {
  name: "IconAlert",
  mixins: [icon_config],
}
</script>

<style scoped>

</style>
