<template>
  <AppMainContainer center-class="offer">
    <template #center>
      <div class="d-flex">
        <h1 class="page-header">
          {{ $t('header.user_option.notifications') }}
        </h1>
      </div>
      <div
        v-if="!loader && notifications"
        class="notifications"
      >
        <div
          v-for="it in notifications"
          :key="it.id"
          class="notifications__item"
        >
          <div class="notifications__icon">
            <IconCheckCircle v-if="it.type === 'info'" />
            <IconAlert
              v-else
              :width="22"
              :height="22"
              :fill="it.type === 'warning' ? '#FAC300' : ''"
            />
          </div>
          <div>
            <div
              class="notifications__description"
              v-html="linkify(it.description, it.link && it.link.title)"
            />
            <div class="notifications__date">
              {{ it.createdDate }} <b>{{ it.createdTime }}</b>
            </div>
          </div>
        </div>
      </div>
      <AppLoader
        v-else
        class="full-height"
      />
    </template>
  </AppMainContainer>
</template>

<script>
import AppMainContainer from '@/components/common/layout/AppMainContainer'
import IconAlert from '@/components/common/icons/IconAlert'
import IconCheckCircle from '@/components/common/icons/IconCheckCircle'
import AppLoader from '@/components/AppLoader'

import { mapActions } from 'vuex'

export default {
  name: 'PageNotifications',
  components: {
    AppMainContainer,
    AppLoader,
    IconAlert,
    IconCheckCircle,
  },
  filters: {
  },
  data: () => ({
    notifications: null,
    loader: false,
  }),
  async created () {
    await this.getNotifications()
  },
  methods: {
    ...mapActions({
      fetchNotifications: 'notification/fetchNotifications',
    }),
    async getNotifications () {
      this.loader = true
      try {
        const { data } = await this.fetchNotifications()
        this.notifications = data
      } finally {
        this.loader = false
      }
    },
    linkify (str, label) {
      return str.replace(/(https?:\/\/[^\s]+)/g, (url) => {
        return `<a href="${url}" class="notifications__link text-green">${label}</a>`
      })
    }
  },
}
</script>

<style lang="sass">
.notifications
  &__item
    display: flex
    flex-direction: row
    align-items: center
    padding: 20px
    padding-top: 14px
    padding-bottom: 23px
    border-bottom: 1px solid rgba(0, 0, 0, 0.15)
    font-style: italic

  &__icon
    margin-right: 22px

  &__description
    margin-bottom: 5px
    font-size: 14px
    line-height: 140%

  &__link
    font-weight: 800
    text-decoration: none

  &__date
    font-size: 12px
    line-height: 15px
    opacity: 0.3
</style>
