<template>
  <svg :width="width || 22" :height="height || 22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="11" cy="11" r="10.25" stroke="#8BD118" stroke-width="1.5"/>
    <path d="M16.9999 7.13137L15.8685 6L8.99993 12.8686L6.13137 10L5 11.1314L8.99985 15.1314L16.9999 7.13137Z" fill="#8BD118"/>
  </svg>
</template>

<script>
import icon_config from "@/mixins/icon_config";

export default {
  name: "IconCheckCircle",
  mixins: [icon_config],
}
</script>

<style scoped>

</style>
